import { Flex, Img, Tag, Text, Box } from "@chakra-ui/react";
import { useProduct } from "../../providers/ProductProvider";
import { svgReturn } from "../../helper/Svg";

export const ProductOutStock = () => {
  const prod = useProduct();

  if (prod?.productOutStock == null) return;

  if (prod?.showReturn) {
    return (
      <Flex onClick={prod?.handlerReturn} justifyContent="left" alignItems="center" px={5} h="40px" mt="15px" bg="#fcf6ff" style={{ cursor: "pointer" }} >
        {svgReturn}
        <Text textAlign="end">ir atrás</Text>
      </Flex>
    );
  }

  return (
    <Flex direction="column" mt="15px">
      <Flex mx={5} py="10px" bg="#F4F4F4" shadow="md" rounded="5px" >
        <Flex w="100%" justifyContent="space-between" wrap="wrap">
          <Flex wrap="wrap">
            <Img mr="15px" src={prod?.productOutStock.image} boxSize="60px" opacity={1} rounded="100%" border="solid 1px #941B1B"></Img>
            {/*  */}
            <Flex direction="column" justifyContent="center">
              <Tag
                size="sm"
                w="43px"
                mr="5px"
                borderRadius="full"
                bg="#941B1B"
                style={{textAlign:"center"}}
              >
                <Box color="white" fontSize="13px" fontWeight="bold" margin="auto">{prod?.productOutStock.sizeName?.slice(0, 4)}</Box>
              </Tag>
              <Text px="5px" pt="5px" color="#941B1B" textAlign={"left"}>
                No disponible en tu zona
              </Text>
            </Flex>
          </Flex>
          <Flex
              justifyContent="flex-end"
              alignItems="center"
              onClick={prod?.handlerReturn}
              style={{ cursor: "pointer" }}
            >
              {svgReturn}
            <Text p="5px" color="purple.700" fontWeight="semibold">
              ir atrás
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Text px={5} pt="15px" color="purple.700">
        {!prod?.productRelated || prod?.productRelated?.length == 0 ? "Talla no disponible" : "Otras opciones para ti"}
      </Text>
    </Flex>
  );
};
