import { getInstance } from "./BrandFactory";
import { checkParameters, isModeTestOn } from "./Parameters";
import { DataConfigService } from "../services/DataConfigService";
import { DataConfigModel } from "../models/DataConfigModel";
import { GlobalPixel } from "./pixel/GlobalPixel.ts";

/** Function to identify the area where it should be rendered.
 * Use the host id to identify the area.
 * @return {HTMLElement} the HTML area
 */
export const tryCloudArea = (): HTMLElement | null | undefined => {
    const brand = getInstance();
    return brand.getArea();
}

/** Executes functions based on the hostid to hidden elements.
 * @return {void} 
 */
export const hiddenElements = (): void => {
    const brand = getInstance();
    brand.hiddenElements();
}

export const hiddenHeader = (header: boolean): void => {
    const brand = getInstance();
    brand.hiddenHeader(header);
}

export const prepareModal = (activate: boolean): void => {
    const brand = getInstance();
    brand.prepareModal(activate);
}

/** Function to show an element based on the host id of each brand.
 * @return {void} No return value
 */
export const showElement = (): void => {
    const brand = getInstance();
    brand.showElement();
}


/** Executes the given function based on the host ID.
 * @param {() => void} execute - The function to execute.
 * @return {void} This function does not return anything.
 */
export const executeApp = async (execute: (dataConfig: DataConfigModel|null) => void): Promise<void> => {
    GlobalPixel.init();
    const configResponse = await DataConfigService.getConfig();
    if (!configResponse) {
        return;
    }
    const brand = getInstance();
    checkParameters();
    if (brand.modeTest && !isModeTestOn()) {
        return;
    }
    brand.startApp(execute, configResponse);
}