import { useState, useContext, useEffect } from "react";
import { AddressModel } from '../models/AddressModel'
import { CustomerModel } from "../models/CustomerModel";
import { AuthService } from "../services/AuthService";
import { AddressService } from "../services/Address/AddressService";
import { AddressContext, AddressContextType } from "../context/AddressContext";
import { useWidget } from "./WidgetProvider";
import { useProduct } from "./ProductProvider";
//import { dataConfig } from "../helper/Config";
//import { SentryApi } from "../helper/Sentry";
import { Hotjar } from "../helper/Hotjar";
import { MessageModel } from "../models/MessageModel";
import { useShoppingCart } from "./ShoppingCartProvider";

export const AddressProvider = ({ children, }: { children: React.JSX.Element; }) => {
    const [address, setAddress] = useState<AddressModel | null>(null);
    const [customer, setCustomer] = useState<CustomerModel | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const widget = useWidget();
    const prod = useProduct();
    const cart = useShoppingCart();

    useEffect(() => {
      setAddress(null);
      if (!widget?.isOn || !AuthService.validateSession()) return;
      getDefaultAddress();
      Hotjar.initialize();
    }, [widget?.isOn])
    
    /** Get the default address asynchronously.
     * @return {Promise<AddressModel | null>} The default address or null if widget is not on or session is not valid.
     */
    const getDefaultAddress = async (): Promise<AddressModel | null> => {
      if (!widget?.isOn || !AuthService.validateSession()) return null;
      const response:  AddressModel | null = await AddressService.getAddressDefault();
      setAddress(response);
      return response;
    }

    /** Change to new address and return a message.
     * @param {AddressModel} address - the address object to be handled
     * @return {Promise<MessageModel>} a promise of a message object
     */
    const changeAddress = async (address:AddressModel): Promise<MessageModel> => {
        setIsLoading(true);
        try {
            const response = await AddressService.addAddress(address);
            if (!response) {
              return {
                code: 400,
                type: "error",
                title: "Disculpe.",
                message: "No pudimos crear tu dirección."
              }
            }
            await getDefaultAddress();
            const shopCart = await cart?.getShoppingCart();
            await prod?.fetchProduct(shopCart);
            //SentryApi.captureMessage(`${dataConfig.metricsPrefix}: New address`);
            return {
              code: 200,
              type: "success",
              title: "Bienvenido.",
              message: "Hemos agregado tu dirección."
            }
        } finally {
          setIsLoading(false);
        }
    }

    /** Returns an object with three parts for availability based on the current time.
     * @return {{firstPart: string, secondPart: string, thirdPar: string}} an object with three parts for availability
     */
    const availabilityService = (): {firstPart: string, secondPart: string, thirdPar: string} => {
        let date = new Date();
        let hours = date.getHours();
        /*if (Data.getHostId() == '781264') {
          const day = date.getDay();
          if ((day == 5 && hours >= 17)  || day == 6 || day == 0) {
            return {
              firstPart: "Llega el lunes ",
              secondPart:"después de las ",
              thirdPar: "10AM."
            }
          }
        }*/
        // Before 10am.
        if (hours < 10){
          return {
            firstPart: "Llega ",
            secondPart:"después de las ",
            thirdPar: "10AM."
          }
        // Between 10am and 5pm.
        } else if (hours >= 10 && hours <= 17){
          return {
            firstPart: "Llega en ",
            secondPart:"90min",
            thirdPar: " ordenando ahora."
          }
        } else {
          // After 5pm.
          return {
            firstPart: "Llega mañana ",
            secondPart:"después de las ",
            thirdPar: "10AM."
          }
        }
    }

    const addressProp: AddressContextType = {
      address, customer, isLoading, setCustomer, getDefaultAddress, changeAddress, availabilityService
    }

    return (
      <AddressContext.Provider value={addressProp}>{children}</AddressContext.Provider>
    );
}

export const useAddress = () => {
    const address:AddressContextType | null = useContext(AddressContext);
    return address;
}