//import { dataConfig } from '../helper/Config';
import { Data } from '../helper/Data';
//import { SentryApi } from '../helper/Sentry';
import { DataConfigModel } from '../models/DataConfigModel';
import { ApiResponse, HttpMethods, makeRequest } from './ApiService';

export class DataConfigService {

    /** Get the config data from the server.
     * @return {Promise<DataConfigModel | null>} the DataConfigModel object, or null if there is an error
     */
    public static async getConfig(): Promise<DataConfigModel | null> {
        try {
            const response: ApiResponse = await makeRequest(HttpMethods.GET, '/config/get?hostId=' + Data.getHostId());
            if (response.error && response.error.message &&  0 < response.error.message.length) {
                throw new Error(response.error.message);
            }
            if (!response.data) {
                return null;
            }
            return response.data as DataConfigModel;
        } catch (error: any) {
            //SentryApi.captureException(new Error(`${dataConfig.metricsPrefix}: DataConfigService::getConfig fail: ${error.message}`));
            return null;
        }
    }
}