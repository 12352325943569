import { DataConfigModel } from "../../models/DataConfigModel";
import { Brands } from "./Brands";

/** Clase para renderizar el widget en el site de Trycloud.
 */
export class Trycloud extends Brands{

    public modeTest: boolean = false;

    public startApp = (execute: (config: DataConfigModel | null) => void, config: DataConfigModel | null) => {
        if (!this.allowRender()) return;
        execute(config);
    } 

    public getArea = (): HTMLElement | null | undefined => {
        const element = document.getElementById('contanerParent');
        if (element) {
           
            const elementTrycloud = document.createElement('div');
            elementTrycloud.id = 'trycloud-area';
            element?.parentNode?.insertBefore(elementTrycloud, element);
            return element;
        }
        console.log("No se encontro el elemento necesario para renderizar el widget");
        return null;
    }

    public hiddenElements = () => {
    }

    public showElement = () => {
    }

    /** Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        return true;
    }

    /** Takes the product id from inputs with name 'product-id' and return it
     * @returns {string} the product id.
     */
    public getProductId = (): string => {
        const productData = document.querySelector("#productData");
        if (productData) {
            return productData.textContent || '';
        }
        return '';
    }
}

(window as any).Trycloud = Trycloud;