import {
  Flex,
  Box,
  Link,
  UnorderedList,
  ListItem,
  Switch,
} from "@chakra-ui/react";
import { useWidget } from "../../providers/WidgetProvider";
import { AuthService } from "../../services/AuthService";
//import { SentryApi } from "../../helper/Sentry";
import { Hotjar } from "../../helper/Hotjar";
//import { dataConfig } from "../../helper/Config";


export const HowToWork = () => {
  const widget = useWidget();
  if (widget == null) return(<></>);

  const handlerHowToWork = () => {
      if (widget.isOn) {
        widget.openCloseModalHowTowork(false);
        return;
      }
      if (!AuthService.validateSession()) {
          widget.openCloseModalAskForAddress(true);
      } else {
        widget.openWidget();
      }
       Hotjar.event('trycloud_activated');
       //SentryApi.captureMessage(`${dataConfig.metricsPrefix}: Activated`);
      widget.openCloseModalHowTowork(false);
  }

  return (
    <Box
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      w="100%"
      h="100%"
      bg="rgba(0, 0, 0, 0.5)"
      zIndex="9000"
      display={!widget.isOpenModalHowTowork ? "none" : "flex"}
      //onClick={() => openCloseModalHowTowork(false)}
    >
      <Flex
        direction="column"
        alignItems="center"
        bg="white"
        border="1px solid #ddd"
        borderRadius="15px"
        w="90%"
        //minW="250px"
        maxW="350px"
        p="1rem"
        gap="0.4rem"
        display={!widget.isOpenModalHowTowork ? "none" : "flex"}
        overflowY="auto"
      >
        <Flex direction="column" mx={5}>
          <Flex justifyContent="right" w="100%">
            <Link w="35px" color="purple.700" fontSize="25px" fontWeight="bold" textAlign="right" textDecoration="none" _hover={{textDecoration:"none"}} onClick={() => widget.openCloseModalHowTowork(false)}>x</Link>
          </Flex>
          <Box fontWeight="bold" fontSize="26px" color="purple.700">
            Pruébatelo antes.
          </Box>
          <Box mt="5px" fontSize="18px" color="purple.700">
            Si no te queda, no pagas nada
          </Box>
          <Box mt="10px" fontWeight="bold" fontSize="21px" color="purple.700">
            ¿Como funciona?
          </Box>
          <Box ml={5} mt="20px">
            <UnorderedList>
              <ListItem>Selecciona hasta 3 productos.</ListItem>
              <ListItem>Recibe y pruébatelo en menos de 24 horas.</ListItem>
              <ListItem>Solo pagas lo que te quede.</ListItem>
              <ListItem>Un repartidor se llevará lo que no quieras.</ListItem>
            </UnorderedList>
          </Box>
          <Flex direction="column" alignItems="center" mt="25px">
            <Box fontSize="16px" fontWeight="bold" color="purple.700">
              {widget.isOn ? "Ya estas en modo probador" : "Activa el modo probador ahora!"}
              
            </Box>
            <Box onClick={handlerHowToWork}>
              <Switch
                size="lg"
                pt={5}
                pl={2}
                pr={2}
                colorScheme="purple"
                
                isChecked={widget.isOn}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
