import { useWidget } from "../../providers/WidgetProvider";
import { HeaderOff } from "../header/HeaderOff"
import { HeaderOn } from "../header/HeaderOn"


export const Header = (): React.JSX.Element => {
  const widget = useWidget();
  return (
   (widget?.isOn) ? <HeaderOn /> : <HeaderOff />
  )
}
