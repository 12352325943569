import React from "react";
import { ShoppingCartModel } from "../models/ShoppingCartModel";
import { Product } from "../models/ProductModel";

export interface ShoppingCartContextType {
  cart: ShoppingCartModel[] | [];
  cartDeleted: ShoppingCartModel[] | [];
  getShoppingCart: () => Promise<ShoppingCartModel[]>;
  addShoppingCart: (
    product: Product,
    TN: string,
    Upc: string,
    colorName: string,
    sizeName: string
  ) => Promise<ShoppingCartModel[] | []>;
  delShoppingCart: (tn: string) => Promise<ShoppingCartModel[] | []>;
}
export const ShoppingCartContext = React.createContext<ShoppingCartContextType | null>(null);
