import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import ReactDOM  from "react-dom/client";
import App from "./App.tsx";
import { WidgetProvider } from "./providers/WidgetProvider";
import { executeApp, tryCloudArea } from "./helper/Render.ts";
//import { dataConfig } from "./helper/Config.ts";
//import { SentryApi } from "./helper/Sentry.ts";
import { DataConfigModel } from "./models/DataConfigModel.ts";

function execute(configResponse:DataConfigModel | null) {
  // Sentry initialization.
  //SentryApi.init(false);
  const element = tryCloudArea();
  if (element) {
    //SentryApi.captureMessage(`${dataConfig.metricsPrefix}: Rendering`);
    ReactDOM.createRoot(element).render(
      <React.StrictMode>
        <ChakraProvider>
          <WidgetProvider configData={configResponse}>
            <App />
          </WidgetProvider>
        </ChakraProvider>
      </React.StrictMode>
    );
  }
}

executeApp(execute);

