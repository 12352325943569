import React from "react";
import { AddressModel } from "../models/AddressModel";
import { CustomerModel } from "../models/CustomerModel";
import { MessageModel } from "../models/MessageModel";

export interface AddressContextType {
  address: AddressModel | null;
  customer: CustomerModel | null;
  isLoading: boolean;
  setCustomer: (value: React.SetStateAction<CustomerModel | null>) => void;
  getDefaultAddress: () => Promise<AddressModel | null>;
  changeAddress: (address: AddressModel) => Promise<MessageModel>;
  availabilityService: () => {
    firstPart: string;
    secondPart: string;
    thirdPar: string;
  }
}

export const AddressContext = React.createContext<AddressContextType | null>(null);
