import { Flex, Text, Img, Divider, Box } from "@chakra-ui/react";
import { useProduct } from "../../providers/ProductProvider";
import { CustomerForm } from "./CustomerForm";
import { svgLocation, svgReturn } from "../../helper/Svg";
import { useWidget } from "../../providers/WidgetProvider";
import { useAddress } from "../../providers/AddressProvider";
import { useEffect } from "react";
import { useShoppingCart } from "../../providers/ShoppingCartProvider";

export const CustomerData = () => {
  const widget = useWidget();
  const prod = useProduct();
  const addr = useAddress();
  const shopCart = useShoppingCart();

  const refreshData = async() => {
    const newcart = await shopCart?.getShoppingCart();
    await prod?.fetchProduct(newcart);
  }

  useEffect(() => {
    refreshData();
  }, []);
  

  if (widget == null) return(<></>);

  const { firstPart, secondPart, thirdPar } = addr?.availabilityService() || {};

  return (
    <Flex direction="column" mx={5}>
      <Flex
        onClick={() => widget.handlerSteps("product")}
        justifyContent="left"
        px={5}
        mt={3}
        bg="#fcf6ff"
        style={{ cursor: "pointer" }}
      >
        {svgReturn}
        <Box textAlign="end" color="purple.700">ir atrás</Box>
      </Flex>
      <Flex direction="row" columnGap="5px" mt="15px" p="5px" bg="#F4F4F4"  shadow="md" rounded="5px">
        <Flex direction="column" w="100%">
          <Text
            color="purple.700"
            fontSize="14px"
            fontWeight="bold"
            textAlign="center"
          >
            Mi selección
          </Text>
          <Flex p="5px" justifyContent="center">
            { 
              prod?.cart && prod?.cart?.length > 0 &&
              // Show cart if cart is not empty.
              prod?.cart?.map((item) => {
                return (
                  <Flex direction="column" mr="-5px" key={item.cardId}>
                    <Img src={item.image} boxSize="60px" opacity={1} rounded="50px"  shadow="md" border="solid 1px white"></Img>
                    <Text textAlign="center" color="purple.700" fontSize="14px">
                      {item.sizeName}
                    </Text>
                  </Flex>
                );
              }) || 
              // Show message if cart is empty.
              <Text textAlign="center" color="purple.700" fontSize="14px">No podemos enviar los productos a la dirección seleccionada.</Text>
            }
          </Flex>
        </Flex>
      </Flex>
      <Box fontSize="10px" textAlign="center" mt="8px">Preparado por: <span style={{color:"#553c9a", fontWeight:"bold"}} >{prod?.cart && prod?.cart?.length > 0 ? prod?.cart[0].merchant : "N/A"}</span></Box>
      <Box mt="15px" fontSize="20px" fontWeight="bold" color="purple.700">
        Datos de Entrega
      </Box>
      <Flex alignItems="center" mt="15px" >
        <Box pr="10px">{svgLocation}</Box>
        <Box>
          Enviar a <span style={{ color: "blue" }}>{addr?.address?.address}</span>
        </Box>
      </Flex>
      {
        // If customer does not have cart, it will not be rendered..
           prod?.cart && prod?.cart?.length > 0 &&
            <>
            <Flex justifyContent="space-between" mt="15px" mx="5px">
              <Box  mb={5} mt={2} fontSize="14px" fontWeight="bold">
                <span style={{ color: "#4BA057" }}>{firstPart}</span>
                <span style={{ color: "#ED693F" }}>{secondPart}</span> <span style={{ color: "#151515" }} >{thirdPar}</span>
                {}
              </Box>
              <Flex direction="column">
                <Box textAlign="right" fontSize="14px" fontWeight="bold">
                  {`$${addr?.address?.commission.toLocaleString('es-co')}`}
                </Box>
                <Box textAlign="right" fontSize="14px" color="purple.700">
                  Gratis si no compras
                </Box>
              </Flex>
            </Flex>
            <Divider mt="15px" />
            <CustomerForm handlerSteps={widget.handlerSteps} />
            </>
      }
    </Flex>

  );
};
