//import { dataConfig } from "../../helper/Config";
//import { SentryApi } from "../../helper/Sentry";
import { Brands } from "./Brands";
import { DataConfigModel } from "../../models/DataConfigModel";


export class DieselCO extends Brands{

    public modeTest: boolean = true;

    public startApp = (execute: (configResponse:DataConfigModel | null) => void, configResponse:DataConfigModel | null) => {

        localStorage.setItem("tcdIsRender", "false");
        this.mutationObserver(() => execute(configResponse));
    } 
  
    public getArea = () : HTMLElement | null | undefined => {
        
        // Botón de agregar producto desktop....
        //let element = document.querySelector("div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--product-page-buybutton");
        let element = document.querySelector("div.vtex-store-components-3-x-skuSelectorContainer.vtex-store-components-3-x-skuSelectorContainer");
        
        // If Element is not null, create element trycloud.   
        if (element != null) {
            // create element trycloud.
            const trycloudArea = document.createElement("div");
            trycloudArea.setAttribute("id", "trycloud-area");
            trycloudArea.setAttribute("name", "trycloud-area");
            
            // Append element trycloud.
            element?.parentNode?.parentNode?.insertBefore(trycloudArea, element?.parentNode.nextSibling);
            return trycloudArea;
        }

        // Send metrics error.
        console.log("Trycloud: No se encontro el elemento necesario para renderizar el widget");
        return null;
    }

    selectElements = () => {
        let elements =[];
        // Element padre del detalla el productos. 
        let elementParent = document.querySelector("div.vtex-flex-layout-0-x-flexCol.vtex-flex-layout-0-x-flexCol--contenedor-info-producto.ml0.mr0.pl0.pr0.flex.flex-column.h-100.w-100")
        let elementTitle = document.querySelector("div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--name-product");
        if (elementTitle != null) {
            elementTitle = elementTitle?.parentElement;
        }
        let trycloudArea = document.getElementById("trycloud-area");
        if (elementParent != null && elementParent.childNodes != null && elementParent.childNodes.length > 0) {
            
            for (let index = 0; index < elementParent.childNodes.length; index++) {
                if (/* elementTitle == elementParent.childNodes[index] || */ trycloudArea == elementParent.childNodes[index]) {
                    continue;
                }
                elements.push(elementParent.childNodes[index]);
            }
        }
        return elements;
   
    }

    public hiddenElements = () => {
        let elements = this.selectElements(); 
        
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "display:none";
            }
        }
    }

    scrollHanler = () => {
        let elementParent = document.querySelector("div.vtex-flex-layout-0-x-flexCol.vtex-flex-layout-0-x-flexCol--contenedor-info-producto.ml0.mr0.pl0.pr0.flex.flex-column.h-100.w-100")
         if (elementParent && elementParent != undefined) {
            elementParent.addEventListener("scroll", () => {
                if (!elementParent && elementParent == undefined) return;
                if (elementParent.scrollTop > 0) {
                    this.showElement();
                } else {
                    this.hiddenElements();
                }
            });
        }
    }

    public showElement = () => {
        let elements = this.selectElements(); 

        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "";
            }
        }
        let elementParent = document.querySelector("div.vtex-flex-layout-0-x-flexCol.vtex-flex-layout-0-x-flexCol--contenedor-info-producto.ml0.mr0.pl0.pr0.flex.flex-column.h-100.w-100")
        if (elementParent != null && elementParent.childNodes != null && elementParent.childNodes.length > 0) {
            elementParent.addEventListener("scroll", () => {
                if (!elementParent && elementParent == undefined) return;
                console.log(elementParent.scrollTop);
            });
        }
    }

    /**
     * Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        //if (window.location.pathname.indexOf('/p') > -1){
        if (window.location.pathname.endsWith('/p')){
            return true;
        }
        return false;
    }

    /* public  getProductId = (): string => {
        const elementData = document.querySelector("body > div.render-container.render-route-store-product > div > div.vtex-store__template.bg-base > div > div > div > script");
        if (!elementData) return '';
        const data = elementData?.innerHTML;
        const dataObj = JSON.parse(data);
        return dataObj.mpn ? `P${dataObj.mpn}` : '';
    };
 */

    public getProductModel = (): string => {
        const metaElement = document.querySelector('meta[property="product:retailer_item_id"]');
        if (!metaElement) return '';
        return metaElement.getAttribute('content') || '';
    };


    public specialRunWhenElementExists = (execute: () => void, numRetries = 10) : Promise<boolean> => {
        localStorage.setItem("tcdIsRender", "true");
        return new Promise((resolve) => {
            const elementButtonDesktop = document.querySelector("div.flex.mt4.mb4.pt0.pb0.justify-start.vtex-flex-layout-0-x-flexRowContent.vtex-flex-layout-0-x-flexRowContent--product-page-buybutton.items-stretch.w-100");
            //const elementProductData = this.getProductIdArea();
            if (elementButtonDesktop || numRetries <= 0) {
                // Hacer algo con el elemento
                console.log('TryCloud: Elemento encontrado.');
                if (!document.querySelector('#trycloud-area')) {
                    execute();
                    resolve(true);
                    localStorage.setItem("tcdIsRender", "false");
                    console.log('TryCloud: El widget se ejecutó correctamente.');
                } else {
                    console.log('TryCloud: El widget ya se encuentra renderizado. No se ejecutó de nuevo. Intentos:', numRetries);
                }
            } else {
                console.log('TryCloud: Esperando el elemento DOM. Intento:', numRetries);
                // Esperar y volver a verificar después de un tiempo
                setTimeout(this.specialRunWhenElementExists.bind(null, execute, numRetries - 1), 1000);
            }
        });
    }

    /** Configura el observador de mutaciones.
     * @param execute 
     */
    public mutationObserver = (execute: () => void) => {
        const observer = this.mutationObserverInstance(execute);
        observer?.observe(document.body, { childList: true, subtree: true });
    }

    /** Instacia el observador de mutaciones.
     * @param execute 
     * @returns 
     */
    public mutationObserverInstance = (execute: () => void,) => {
        var nodeList = [];
        const observer = new MutationObserver((mutationsList) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    if (mutation.target instanceof HTMLElement) {
                        if (this.allowRender()) {
                            nodeList.push(mutation.target.nodeName);
                        } else {
                            document.getElementById('trycloud-area')?.remove();
                        }
                    }
                }
            }
            if (localStorage.getItem("tcdIsRender") === "false" && nodeList.length > 0) {   
                 // If don't is render now and has changes in the DOM and is allow render (page product) and widget is not render yet.
                if (nodeList.length > 0 && this.allowRender() && !document.querySelector('#trycloud-area')) {
                    console.log(`TryCloud: NodeList: ${nodeList.length} | isExecuted: ${localStorage.getItem("tcdIsRender")} | allowRender: ${this.allowRender()}`);
                }        
                // compare productId
                this.compareProductId(); 
                nodeList = [];
                if (!document.querySelector('#trycloud-area')) {
                    console.log('TryCloud: Iniciando desde el observador...');
                    this.specialRunWhenElementExists(execute);
                } else {
                    // Validate if widget is Open to hide elements.
                    if (localStorage.getItem("tryCloud") === "true") {
                        this.hiddenElements();
                    }
                    console.log('TryCloud: Cambios detectados, pero el widget ya se encuentra renderizado.');
                }
            }
        });
        return observer;
    }

    /** Compare productId to know if it has changed */
    public compareProductId = () => {
        const productId = this.getProductId();
        if (productId) {
            if (productId !== localStorage.getItem("tcdProductId")) {
                localStorage.setItem("tcdProductId", productId);``
                document.getElementById('trycloud-area')?.remove();
            }
        }
    }
}