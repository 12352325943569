import { Product } from "../product/Product";
import { PhoneVerified } from "../phone/PhoneVerified";
import { CustomerData } from "../customer/CustomerData";
import { OrderSumary } from "../order/OrderSumary";
import { Footer } from "./Footer";
import { useWidget } from "../../providers/WidgetProvider";
import { Address } from "../address/Address";

export const Steps = () => {

  const widget = useWidget();
  // If the widget is not on, it will not be rendered.
  if (widget == null || !widget.isOn) return; 

  // Switch case for each step.
  switch (widget.step) {
    case "product":
      return (
        <>
          <Product />
          <Footer />
        </>
      );
    case "phone":
      return (
        <>
          <PhoneVerified/>
          <Footer />

        </>
      );
    case "address":
      return (
        <>
          <Address/>
          <Footer />

        </>
      );
    case "data-customer":
      return (
        <>
          <CustomerData />
          <Footer />
        </>
      );
    case "finish":
      return (
        <>
          <OrderSumary/>
          <Footer />

        </>
      );
    default:
      break;
  }
};
