import { Hotjar } from "../helper/Hotjar.ts";
import { DataConfigModel } from "../models/DataConfigModel.ts";


export const useDataConfig = () => {

    /** This function starts the process of showing the first time modal.
     * @return {Promise<boolean>}
     */
    const startShowFirstTimeModal = async (setIsOpenModalAskForAddress: (state: boolean) => void, configResponse: DataConfigModel | null): Promise<void> => {
        if (!configResponse || configResponse.openFirstModal.timeToRetryToActivateWidget === 0){
            return;
        };
        const tryCloudWasActivated = localStorage.getItem("tryCloudWasActivated") as string;
        const lastTimeUsed = localStorage.getItem("lastTimeUsed") as string;
        const timeFromLastUse = new Date().getTime() - new Date(lastTimeUsed).getTime();
       
        if (tryCloudWasActivated === "false" || 
            tryCloudWasActivated === null || 
            timeFromLastUse > configResponse.openFirstModal.timeToRetryToActivateWidget) {
            setTimeout(() => {
                const tryCloud = localStorage.getItem("tryCloud") as string;
                if (tryCloud !== "true"){// Ya esta abierto el widget, asi que no hay que mostrarlo.
                    setIsOpenModalAskForAddress(true);
                    Hotjar.event('trycloud_activated');
                    Hotjar.funnelModalTimer();
                }
                setDataWidgetOpen();// Igual marcamos para que no se intente mostrar el widget de nuevo.
            }, configResponse.openFirstModal.timeToShowFirstModal)
        }
    }

    /** This function sets the widget open data for the first time.
     * @return {void}
     */
    const setDataWidgetOpen = () => {
        localStorage.setItem("tryCloudWasActivated", "true");
        localStorage.setItem("lastTimeUsed", new Date().toString());
    }
    return {startShowFirstTimeModal, setDataWidgetOpen}
}
