import { Flex, Text, Img, Button, Box } from "@chakra-ui/react";
import { useOrder } from "../../hooks/useOrder";
import { /* svgBrand, */ svgCheckCircle, svgLocation } from "../../helper/Svg";
import { useEffect } from "react";
import { useAddress } from "../../providers/AddressProvider";

export const OrderSumary = () => {
  const { order, getOrder } = useOrder();
  const addr = useAddress();

  useEffect(() => {
    getOrder();
  }, []);

  if (!order) return null;

  const { firstPart, secondPart, thirdPar } = addr?.availabilityService() || {};

  return (
    <Flex direction="column" px={5}>
      <Text pt="10px" fontSize="20px" color="purple.700" fontWeight="bold">
        Hemos recibido tu pedido
      </Text>
      <Flex
        alignItems="center"
        justifyContent="center"
        //border="1px"
        boxShadow="xl"
        borderColor="gray.200"
      >
        {svgCheckCircle}
        <Text fontSize="25px" color="#50AC64" fontWeight="bold">
          #{order?.order_id ?? "000000"}
        </Text>
      </Flex>

      <Flex direction="row" columnGap="5px" p="5px" bg="#FAF7FF">
        <Flex direction="column" w="50%">
          <Text pt="10px" fontSize="15px" color="purple.700" fontWeight="bold" textAlign={"left"}>
            Mi selección
          </Text>
          <Flex p="10px">
            {order?.products?.map((item) => {
              return (
                <Flex direction="column" mr="-5px" key={item.order_product_id}>
                  <Img src={item.image} boxSize="60px" rounded="50px" opacity={1}></Img>
                  <Text textAlign="center" color="purple.700" fontSize="14px">
                    {item.size}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        <Flex direction="column" justifyContent="space-around" >
          <Button as ="a" href={`https://api.whatsapp.com/send?phone=18143288401&text=Hola he generado la orden probador Nro${order?.order_id}`}>Abrir Whatsapp</Button>
          <Flex direction="row" alignItems="flex-end" >
            <Text color="purple.700" fontWeight="bold" fontSize="14px">
              Preparado por:
            </Text>
            <Text color="purple.700" fontSize="14px" ml="5px">
              {order?.brand_name}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Text pt="10px" color="purple.700" fontWeight="bold" fontSize="17px">
        {order?.customer_first_name ?? "Nombre del cliente"}
      </Text>
      <Flex alignItems="center" pt="10px">
        <Text pr="10px">{svgLocation}</Text>
        <Text color="purple.700">{order?.address ?? "Address de cliente"}</Text>
      </Flex>
      <Flex justifyContent="space-between" mt="15px">
        <Flex direction="column">
          <Box  mb={5} mt={2} fontSize="14px" fontWeight="bold">
            <span style={{ color: "#4BA057" }}>{firstPart}</span>
            <span style={{ color: "#ED693F" }}>{secondPart}</span> <span style={{ color: "#151515" }} >{thirdPar}</span>
            {}
          </Box>
          <Text color="#ED693F" fontSize="14px" fontWeight="bold">
            Entrega inmediata
          </Text>
        </Flex>
        <Flex direction="column">
          <Text textAlign="right" fontSize="18px">
            {`${order?.commision.toLocaleString()}`}
          </Text>
          <Text textAlign="right" fontSize="16px">
            Gratis si no compras
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
