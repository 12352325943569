import Clarity from '@microsoft/Clarity';
import { Data } from '../Data';

export class ClarityModule {

    public static init = () => {
        //if (document.location.hostname.includes('localhost')) return;
        if (typeof window !== 'undefined') {
            const projectId: string = ClarityModule.getClarityId();
            Clarity.init(projectId);
        }
    };

    public static trackEvent(eventName: any) {
        if (typeof window !== 'undefined') {
            Clarity.event(eventName);
        }
    }


    /* Retrieves the Clarity ID based on the host ID.
    * @return {number} The Clarity ID corresponding to the host ID.
    */
    public static getClarityId = (): string => {
        const hostId = Data.getHostId();
        switch (hostId) {
            case "279038":  // "NewBalanceCO" 
                return 'p0q7qyj344';
            case "589462":  // "Mali" 
                return 'p0q8uc68ne';
            case "785421":  // "Bamers" 
                return 'p0q9bzsorb';
            case "874530":  // "Trycloud" 
                return '00000';
            case "781264":  // "TheNorthFaceCo"
                return 'oo6xplkiex';
            case "554044":  // "BosiCO" 
                return 'p0q9vqtyrg';
            case "876870":  // "PilatosCO" 
                return 'p0qatt6qry';
            case "393901":  // "SuperDryCO" 
                return 'p0qbebg95m';
            case "693510":  // "DieselCO" 
                return 'p0qc460szb';
            case "333331":  // "Prochampions" 
                return 'p0qcsf7gv8';
            case "956487":  // "SteveMaddenCO" 
                return 'p0qdh5x0bx';
            case "4555531":  // "RunningBalboa" 
                return 'p0qe5jiz8u';
            case "314159":  // "ParisCL" 
                return '00000';
            case "218350":  // "VansCO" 
                return 'p0qemfse3h';
            case "989764":  // "MFGirbaudCO" 
                return 'p0qf92knqo';
            case "635987":  // "NBxPilatos" 
                return '00000';
            default:
                return '00000';
        }
    }
}