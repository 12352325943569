import { Box, Divider, Flex, Img, Text } from '@chakra-ui/react'
import { useWidget } from "../../providers/WidgetProvider";
import { useAddress } from '../../providers/AddressProvider';

export const Footer = () => {
  const widget = useWidget();
  const addr = useAddress();
  if (widget == null) return(<></>);

  const paymentMethod = () => {
    switch (addr?.address?.country_name || "--") {
      case "Colombia":
        return "PSE, Visa, Mastercard, Amex, Bancolombia, Nequi, entre otros.";
      case "Chile":
        return "Mercado Pago, Visa, Mastercard, AmericaExpress, Cencosud, CRM Falabella, Redcompra y más.";
      default:
        return "Mercado Pago, Wompi";
    }
  } 

  return (
    <>
        <Divider  orientation='horizontal' w="95%" margin="15px auto" borderColor="#808080"/>
        <Flex direction="column" mx={5}>
            <Flex>
                <Text w="25%" color="#808080" mb="10px" fontSize="15px" textAlign={"left"}>Condiciones</Text>
                <Text w="75%" color="#4A93A1" mb="10px" fontSize="15px" lineHeight="16px" textAlign={"left"}>
                  {`Gratis si no te quedó`}<br/>
                <span style={{fontSize:"11px", color:"#4A93A1"}}>INCLUYE DESPACHO Y DEVOLUCION</span>
                </Text>
            </Flex>
            <Flex>
                <Text w="25%" color="#808080" mb="10px" fontSize="15px" textAlign={"left"}>Paga con</Text>
                <Text w="75%" color="#4A93A1" mb="10px" fontSize="15px" textAlign={"left"}>{paymentMethod()}</Text>
            </Flex>
            <Divider  orientation='horizontal' w="100%" mx={"auto"} mb={2} borderColor="#808080"/>
            <Flex direction="row"  justifyContent={"right"}>
              <Box mr={2} fontSize={"12px"} lineHeight={"10px"} mt={0} color={"#808080"}>Powered by</Box>
              <Img 
                src="https://trycloud.s3.us-east-2.amazonaws.com/assets/images/Type-logo-Trycloud.png" 
                alt="trycloud logo"
                h={"12px"}
                w={"53px"}
                opacity={1}
                />
            </Flex>
        </Flex>
    </>
  )
}
