export function callingCodes() {
    return {
        "AF": {primary:"Afganistán", secondary:"93"},
        "AL": {primary:"Albania", secondary:"355"},
        "DE": {primary:"Alemania", secondary:"49"},
        "AD": {primary:"Andorra", secondary:"376"},
        "AO": {primary:"Angola", secondary:"244"},
        "AI": {primary:"Anguila", secondary:"1 264"},
        "AQ": {primary:"Antártida", secondary:"672"},
        "AG": {primary:"Antigua y Barbuda", secondary:"1 268"},
        "SA": {primary:"Arabia Saudita", secondary:"966"},
        "DZ": {primary:"Argelia", secondary:"213"},
        "AR": {primary:"Argentina", secondary:"54"},
        "AM": {primary:"Armenia", secondary:"374"},
        "AW": {primary:"Aruba", secondary:"297"},
        "AU": {primary:"Australia", secondary:"61"},
        "AT": {primary:"Austria", secondary:"43"},
        "AZ": {primary:"Azerbaiyán", secondary:"994"},
        "BE": {primary:"Bélgica", secondary:"32"},
        "BS": {primary:"Bahamas", secondary:"1 242"},
        "BH": {primary:"Bahrein", secondary:"973"},
        "BD": {primary:"Bangladesh", secondary:"880"},
        "BB": {primary:"Barbados", secondary:"1 246"},
        "BZ": {primary:"Belice", secondary:"501"},
        "BJ": {primary:"Benín", secondary:"229"},
        "BT": {primary:"Bhután", secondary:"975"},
        "BY": {primary:"Bielorrusia", secondary:"375"},
        "MM": {primary:"Birmania", secondary:"95"},
        "BO": {primary:"Bolivia", secondary:"591"},
        "BA": {primary:"Bosnia y Herzegovina", secondary:"387"},
        "BW": {primary:"Botsuana", secondary:"267"},
        "BR": {primary:"Brasil", secondary:"55"},
        "BN": {primary:"Brunéi", secondary:"673"},
        "BG": {primary:"Bulgaria", secondary:"359"},
        "BF": {primary:"Burkina Faso", secondary:"226"},
        "BI": {primary:"Burundi", secondary:"257"},
        "CV": {primary:"Cabo Verde", secondary:"238"},
        "KH": {primary:"Camboya", secondary:"855"},
        "CM": {primary:"Camerún", secondary:"237"},
        "CA": {primary:"Canadá", secondary:"1"},
        "TD": {primary:"Chad", secondary:"235"},
        "CL": {primary:"Chile", secondary:"56"},
        "CN": {primary:"China", secondary:"86"},
        "CY": {primary:"Chipre", secondary:"357"},
        "VA": {primary:"Ciudad del Vaticano", secondary:"39"},
        "CO": {primary:"Colombia", secondary:"57"},
        "KM": {primary:"Comoras", secondary:"269"},
        "CG": {primary:"República del Congo", secondary:"242"},
        "CD": {primary:"República Democrática del Congo", secondary:"243"},
        "KP": {primary:"Corea del Norte", secondary:"850"},
        "KR": {primary:"Corea del Sur", secondary:"82"},
        "CI": {primary:"Costa de Marfil", secondary:"225"},
        "CR": {primary:"Costa Rica", secondary:"506"},
        "HR": {primary:"Croacia", secondary:"385"},
        "CU": {primary:"Cuba", secondary:"53"},
        "CW": {primary:"Curazao", secondary:"5999"},
        "DK": {primary:"Dinamarca", secondary:"45"},
        "DM": {primary:"Dominica", secondary:"1 767"},
        "EC": {primary:"Ecuador", secondary:"593"},
        "EG": {primary:"Egipto", secondary:"20"},
        "SV": {primary:"El Salvador", secondary:"503"},
        "AE": {primary:"Emiratos Árabes Unidos", secondary:"971"},
        "ER": {primary:"Eritrea", secondary:"291"},
        "SK": {primary:"Eslovaquia", secondary:"421"},
        "SI": {primary:"Eslovenia", secondary:"386"},
        "ES": {primary:"España", secondary:"34"},
        "US": {primary:"Estados Unidos de América", secondary:"1"},
        "EE": {primary:"Estonia", secondary:"372"},
        "ET": {primary:"Etiopía", secondary:"251"},
        "PH": {primary:"Filipinas", secondary:"63"},
        "FI": {primary:"Finlandia", secondary:"358"},
        "FJ": {primary:"Fiyi", secondary:"679"},
        "FR": {primary:"Francia", secondary:"33"},
        "GA": {primary:"Gabón", secondary:"241"},
        "GM": {primary:"Gambia", secondary:"220"},
        "GE": {primary:"Georgia", secondary:"995"},
        "GH": {primary:"Ghana", secondary:"233"},
        "GI": {primary:"Gibraltar", secondary:"350"},
        "GD": {primary:"Granada", secondary:"1 473"},
        "GR": {primary:"Grecia", secondary:"30"},
        "GL": {primary:"Groenlandia", secondary:"299"},
        "GP": {primary:"Guadalupe", secondary:"590"},
        "GU": {primary:"Guam", secondary:"1 671"},
        "GT": {primary:"Guatemala", secondary:"502"},
        "GF": {primary:"Guayana Francesa", secondary:"594"},
        "GG": {primary:"Guernsey", secondary:"44"},
        "GN": {primary:"Guinea", secondary:"224"},
        "GQ": {primary:"Guinea Ecuatorial", secondary:"240"},
        "GW": {primary:"Guinea-Bissau", secondary:"245"},
        "GY": {primary:"Guyana", secondary:"592"},
        "HT": {primary:"Haití", secondary:"509"},
        "HN": {primary:"Honduras", secondary:"504"},
        "HK": {primary:"Hong kong", secondary:"852"},
        "HU": {primary:"Hungría", secondary:"36"},
        "IN": {primary:"India", secondary:"91"},
        "ID": {primary:"Indonesia", secondary:"62"},
        "IR": {primary:"Irán", secondary:"98"},
        "IQ": {primary:"Irak", secondary:"964"},
        "IE": {primary:"Irlanda", secondary:"353"},
        "IM": {primary:"Isla de Man", secondary:"44"},
        "CX": {primary:"Isla de Navidad", secondary:"61"},
        "NF": {primary:"Isla Norfolk", secondary:"672"},
        "IS": {primary:"Islandia", secondary:"354"},
        "BM": {primary:"Islas Bermudas", secondary:"1 441"},
        "KY": {primary:"Islas Caimán", secondary:"1 345"},
        "CC": {primary:"Islas Cocos (Keeling)", secondary:"61"},
        "CK": {primary:"Islas Cook", secondary:"682"},
        "AX": {primary:"Islas de Åland", secondary:"358"},
        "FO": {primary:"Islas Feroe", secondary:"298"},
        "GS": {primary:"Islas Georgias del Sur y Sandwich del Sur", secondary:"500"},
        "MV": {primary:"Islas Maldivas", secondary:"960"},
        "FK": {primary:"Islas Malvinas", secondary:"500"},
        "MP": {primary:"Islas Marianas del Norte", secondary:"1 670"},
        "MH": {primary:"Islas Marshall", secondary:"692"},
        "PN": {primary:"Islas Pitcairn", secondary:"870"},
        "SB": {primary:"Islas Salomón", secondary:"677"},
        "TC": {primary:"Islas Turcas y Caicos", secondary:"1 649"},
        "UM": {primary:"Islas Ultramarinas Menores de Estados Unidos", secondary:"246"},
        "VG": {primary:"Islas Vírgenes Británicas", secondary:"1 284"},
        "VI": {primary:"Islas Vírgenes de los Estados Unidos", secondary:"1 340"},
        "IL": {primary:"Israel", secondary:"972"},
        "IT": {primary:"Italia", secondary:"39"},
        "JM": {primary:"Jamaica", secondary:"1 876"},
        "JP": {primary:"Japón", secondary:"81"},
        "JE": {primary:"Jersey", secondary:"44"},
        "JO": {primary:"Jordania", secondary:"962"},
        "KZ": {primary:"Kazajistán", secondary:"7"},
        "KE": {primary:"Kenia", secondary:"254"},
        "KG": {primary:"Kirguistán", secondary:"996"},
        "KI": {primary:"Kiribati", secondary:"686"},
        "KW": {primary:"Kuwait", secondary:"965"},
        "LB": {primary:"Líbano", secondary:"961"},
        "LA": {primary:"Laos", secondary:"856"},
        "LS": {primary:"Lesoto", secondary:"266"},
        "LV": {primary:"Letonia", secondary:"371"},
        "LR": {primary:"Liberia", secondary:"231"},
        "LY": {primary:"Libia", secondary:"218"},
        "LI": {primary:"Liechtenstein", secondary:"423"},
        "LT": {primary:"Lituania", secondary:"370"},
        "LU": {primary:"Luxemburgo", secondary:"352"},
        "MX": {primary:"México", secondary:"52"},
        "MC": {primary:"Mónaco", secondary:"377"},
        "MO": {primary:"Macao", secondary:"853"},
        "MK": {primary:"Macedônia", secondary:"389"},
        "MG": {primary:"Madagascar", secondary:"261"},
        "MY": {primary:"Malasia", secondary:"60"},
        "MW": {primary:"Malawi", secondary:"265"},
        "ML": {primary:"Mali", secondary:"223"},
        "MT": {primary:"Malta", secondary:"356"},
        "MA": {primary:"Marruecos", secondary:"212"},
        "MQ": {primary:"Martinica", secondary:"596"},
        "MU": {primary:"Mauricio", secondary:"230"},
        "MR": {primary:"Mauritania", secondary:"222"},
        "YT": {primary:"Mayotte", secondary:"262"},
        "FM": {primary:"Micronesia", secondary:"691"},
        "MD": {primary:"Moldavia", secondary:"373"},
        "MN": {primary:"Mongolia", secondary:"976"},
        "ME": {primary:"Montenegro", secondary:"382"},
        "MS": {primary:"Montserrat", secondary:"1 664"},
        "MZ": {primary:"Mozambique", secondary:"258"},
        "NA": {primary:"Namibia", secondary:"264"},
        "NR": {primary:"Nauru", secondary:"674"},
        "NP": {primary:"Nepal", secondary:"977"},
        "NI": {primary:"Nicaragua", secondary:"505"},
        "NE": {primary:"Niger", secondary:"227"},
        "NG": {primary:"Nigeria", secondary:"234"},
        "NU": {primary:"Niue", secondary:"683"},
        "NO": {primary:"Noruega", secondary:"47"},
        "NC": {primary:"Nueva Caledonia", secondary:"687"},
        "NZ": {primary:"Nueva Zelanda", secondary:"64"},
        "OM": {primary:"Omán", secondary:"968"},
        "NL": {primary:"Países Bajos", secondary:"31"},
        "PK": {primary:"Pakistán", secondary:"92"},
        "PW": {primary:"Palau", secondary:"680"},
        "PS": {primary:"Palestina", secondary:"970"},
        "PA": {primary:"Panamá", secondary:"507"},
        "PG": {primary:"Papúa Nueva Guinea", secondary:"675"},
        "PY": {primary:"Paraguay", secondary:"595"},
        "PE": {primary:"Perú", secondary:"51"},
        "PF": {primary:"Polinesia Francesa", secondary:"689"},
        "PL": {primary:"Polonia", secondary:"48"},
        "PT": {primary:"Portugal", secondary:"351"},
        "PR": {primary:"Puerto Rico", secondary:"1"},
        "QA": {primary:"Qatar", secondary:"974"},
        "GB": {primary:"Reino Unido", secondary:"44"},
        "CF": {primary:"República Centroafricana", secondary:"236"},
        "CZ": {primary:"República Checa", secondary:"420"},
        "DO": {primary:"República Dominicana", secondary:"1 809"},
        "SS": {primary:"República de Sudán del Sur", secondary:"211"},
        "RE": {primary:"Reunión", secondary:"262"},
        "RW": {primary:"Ruanda", secondary:"250"},
        "RO": {primary:"Rumanía", secondary:"40"},
        "RU": {primary:"Rusia", secondary:"7"},
        "EH": {primary:"Sahara Occidental", secondary:"212"},
        "WS": {primary:"Samoa", secondary:"685"},
        "AS": {primary:"Samoa Americana", secondary:"1 684"},
        "BL": {primary:"San Bartolomé", secondary:"590"},
        "KN": {primary:"San Cristóbal y Nieves", secondary:"1 869"},
        "SM": {primary:"San Marino", secondary:"378"},
        "MF": {primary:"San Martín (Francia)", secondary:"1 599"},
        "PM": {primary:"San Pedro y Miquelón", secondary:"508"},
        "VC": {primary:"San Vicente y las Granadinas", secondary:"1 784"},
        "SH": {primary:"Santa Elena", secondary:"290"},
        "LC": {primary:"Santa Lucía", secondary:"1 758"},
        "ST": {primary:"Santo Tomé y Príncipe", secondary:"239"},
        "SN": {primary:"Senegal", secondary:"221"},
        "RS": {primary:"Serbia", secondary:"381"},
        "SC": {primary:"Seychelles", secondary:"248"},
        "SL": {primary:"Sierra Leona", secondary:"232"},
        "SG": {primary:"Singapur", secondary:"65"},
        "SX": {primary:"Sint Maarten", secondary:"1 721"},
        "SY": {primary:"Siria", secondary:"963"},
        "SO": {primary:"Somalia", secondary:"252"},
        "LK": {primary:"Sri lanka", secondary:"94"},
        "ZA": {primary:"Sudáfrica", secondary:"27"},
        "SD": {primary:"Sudán", secondary:"249"},
        "SE": {primary:"Suecia", secondary:"46"},
        "CH": {primary:"Suiza", secondary:"41"},
        "SR": {primary:"Surinám", secondary:"597"},
        "SJ": {primary:"Svalbard y Jan Mayen", secondary:"47"},
        "SZ": {primary:"Swazilandia", secondary:"268"},
        "TJ": {primary:"Tayikistán", secondary:"992"},
        "TH": {primary:"Tailandia", secondary:"66"},
        "TW": {primary:"Taiwán", secondary:"886"},
        "TZ": {primary:"Tanzania", secondary:"255"},
        "IO": {primary:"Territorio Británico del Océano Índico", secondary:"246"},
        "TL": {primary:"Timor Oriental", secondary:"670"},
        "TG": {primary:"Togo", secondary:"228"},
        "TK": {primary:"Tokelau", secondary:"690"},
        "TO": {primary:"Tonga", secondary:"676"},
        "TT": {primary:"Trinidad y Tobago", secondary:"1 868"},
        "TN": {primary:"Tunez", secondary:"216"},
        "TM": {primary:"Turkmenistán", secondary:"993"},
        "TR": {primary:"Turquía", secondary:"90"},
        "TV": {primary:"Tuvalu", secondary:"688"},
        "UA": {primary:"Ucrania", secondary:"380"},
        "UG": {primary:"Uganda", secondary:"256"},
        "UY": {primary:"Uruguay", secondary:"598"},
        "UZ": {primary:"Uzbekistán", secondary:"998"},
        "VU": {primary:"Vanuatu", secondary:"678"},
        "VE": {primary:"Venezuela", secondary:"58"},
        "VN": {primary:"Vietnam", secondary:"84"},
        "WF": {primary:"Wallis y Futuna", secondary:"681"},
        "YE": {primary:"Yemen", secondary:"967"},
        "DJ": {primary:"Yibuti", secondary:"253"},
        "ZM": {primary:"Zambia", secondary:"260"},
        "ZW": {primary:"Zimbabue", secondary:"263"},
    };  
}