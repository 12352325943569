//import { dataConfig } from "../../helper/Config";
//import { SentryApi } from "../../helper/Sentry";
import { DataConfigModel } from "../../models/DataConfigModel";
import { Brands } from "./Brands";

export class TheNorthFace extends Brands{

    public modeTest: boolean = true;

    public startApp = (execute: (configResponse:DataConfigModel | null) => void, configResponse:DataConfigModel | null) => {
        if (!this.allowRender()) return;
        execute(configResponse);
    } 

    public getArea = (): HTMLElement | null | undefined => {
        
        //const element:HTMLElement = document.querySelector("#product-content div.product-details div.product-info div.container-sku-box div.similar-prod") as HTMLElement;
        const element:HTMLElement = document.querySelector("#fancytalles") as HTMLElement;
        //fancytalles
        if (element) {
            //product-info
            const productInfo:HTMLElement = document.querySelector("#product-content div.product-details div.product-info") as HTMLElement;
            if (productInfo) {
                productInfo.style.cssText = "z-index:100";
            }
            // create element trycloud.
            let elementTrycloud = document.createElement('div');
            elementTrycloud.style.cssFloat = "left";
            elementTrycloud.style.marginLeft = "-25px";
            elementTrycloud.id = 'trycloud-area';
            // Append element trycloud. 
            element?.parentNode?.insertBefore(elementTrycloud, element.nextSibling);
            return elementTrycloud;
        }
        //SentryApi.captureException(new Error(`${dataConfig.metricsPrefix}: No se encontro el elemento necesario para renderizar el widget`));
        console.log("No se encontro el elemento necesario para renderizar el widget");
        return null;
    }

    public hiddenElements = () => {
        const element:HTMLElement = document.querySelector("#product-content div.product-details div.product-info div.container-sku-box div.sku-selector-box div.sku-selector-container.sku-selector-container-0") as HTMLElement;
        if (element) {
            element.style.cssText = "display:none";
        }
        const buyButton:HTMLElement = document.querySelector("#product-content div.product-details div.product-info div.buy-button-box") as HTMLElement;
        if (buyButton) {
            buyButton.style.cssText = "display:none";
        }
        const addi = document.querySelector("addi-product-widget") as HTMLElement;
        if (addi) {
            addi.style.cssText = "display:none";
        }
    }

    public showElement = () => {
        const element:HTMLElement = document.querySelector("#product-content div.product-details div.product-info div.container-sku-box div.sku-selector-box div.sku-selector-container.sku-selector-container-0") as HTMLElement;
        if (element) {
            element.removeAttribute('style');
        }
        const buyButton:HTMLElement = document.querySelector("#product-content div.product-details div.product-info div.buy-button-box") as HTMLElement;
        if (buyButton) {
            buyButton.removeAttribute('style');
        }
        const addi = document.querySelector("addi-product-widget") as HTMLElement;
        if (addi) {
            addi.removeAttribute('style');
        }
    }

    /** Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        if (window.location.pathname.indexOf('/p') > -1){
            return true;
        }
        return false;
    }

    /** Takes the product id from input and return it
     * @returns {string} the product id.
     */
    public getProductId = (): string => {
        const productDataElement:HTMLInputElement = document.getElementById('___rc-p-id') as HTMLInputElement;
        if (productDataElement == null) return '';
        const productData = JSON.parse(productDataElement.value);
        return `P${productData}`;
    }
}