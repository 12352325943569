import { getInstance } from "./BrandFactory";

export class Data{
    
    /**
     * Gets the host id.
     * @returns {string} The host id.
     */
    public static getHostId = (): string => {
        const scriptElement = document.getElementById("trycloud-app");
        const dataId = scriptElement?.getAttribute("data-id");
        return dataId ?? "";
    };

    /**
     * Gets el id del producto en la página.
     * @returns {string} El id del producto en la página.
     */
    public static getProductId = (): string => {
        const brand = getInstance();
        return brand.getProductId();
    }

    public static getProductModel = (): string => {
        const brand = getInstance();
        return brand.getProductModel();
    }

    public static getProductSku = (): string => {
        const brand = getInstance();
        return brand.getSkuId() ?? "";
    }

    /**
     * Gets the platform.
     * @returns {string} The platform.
     */
    public static getPlatform = (): string => {
        const scriptElement = document.getElementById("trycloud-app");
        const dataPlatform = scriptElement?.getAttribute("data-plat");
        return dataPlatform ?? "";
    }

    /**
     * Verifies if the environment is development.
     * @returns {boolean} Whether the environment is development or not.
     */
    public static isDevelopmentEnv = (): boolean =>  document.location.hostname.includes('localhost') || document.location.hostname.includes('trucloud-test.s3.us-east-2.amazonaws.com') || document.location.hostname.includes('trycloud.s3.us-east-2.amazonaws');

    public static getMetaContentByProperty = (property: string) => {
        // Seleccionar la etiqueta meta con el atributo property especificado
        const metaTag = document.querySelector(`meta[property='${property}']`);
    
        // Verificar si la etiqueta meta existe y devolver el valor del atributo content
        if (metaTag) {
            return metaTag.getAttribute('content');
        } else {
            console.log(`Meta tag with property '${property}' not found.`);
            return null;
        }
    }
    
}