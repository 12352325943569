import { useState } from "react";
import { OrderModel } from "../models/OrderModel";
import { OrderServices } from "../services/OrderServices";
//import { OrderServicesTest } from "../services/OrderServicesTest";

export const useOrder = () => {
    const [order, setOrder] = useState<OrderModel | null>(null)

    /** This function retrieves an order and sets it in the local state.
     * @return {Promise<boolean>} Whether the order was successfully retrieved and set
     */
    const getOrder = async (): Promise<boolean> => {
        const orderResponse = await OrderServices.getOrder();
       /*  OrderServicesTest.getOrderTest()
            .then((responseTest): void =>{
                console.log("Response OrderServicesTest: ",responseTest);
            }); */
        if (!orderResponse) return false;
        setOrder(orderResponse);
        return true;
    }

    /** A function that creates an order asynchronously.
     * @return {Promise<boolean>} Whether the order was successfully created
     */
    const createOrder = async (): Promise<boolean> => {
        const orderResponse = await OrderServices.createOrder();
        if (!orderResponse) return false;
        setOrder(orderResponse);
        return true;
    }

    return { order, getOrder, createOrder }
}
