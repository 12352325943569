import * as Sentry from "@sentry/react";

export class SentryApi {

    public static captureException(error: Error) {
        Sentry.captureException(error);
    }

    public static captureMessage(message: string) {
        Sentry.captureMessage(message);
    }

    public static init(isActive: boolean) {

        // if isActive is false, do not initialize,
        if (!isActive) return;

        // Initialize sentry.
        try {
            Sentry.init({
              dsn: "https://cb980f306608a9faad1578fdd0c12070@o4506742063235072.ingest.sentry.io/4506742065659904",
            /*  beforeSend(event, hint) {
                const error: any = hint.originalException;
                if (error && error?.message && error?.message.match(/Widget-TryCloud/)) {
                  return null;
                }
                return event;
              }, */
              integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                  maskAllText: false,
                  blockAllMedia: false,
                }),
              ],
              // Performance Monitoring
              tracesSampleRate: 1.0, //  Capture 100% of the transactions
              // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
              tracePropagationTargets: ["localhost", /^https:\/\/newbalance\.com\.co/, /^https:\/\/www\.newbalance\.com\.co/, /^https:\/\/www\.malishop\.cl/],
              // Session Replay
              replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
              replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });
          } catch (error) {
            console.log('Sentry: ya ha sido inicializado');
          }  
    }
}