import { Box, Button, Flex, Text, VStack } from "@chakra-ui/react";
import { svgLocation2 } from "../../helper/Svg";
import { useWidget } from "../../providers/WidgetProvider";

export default function ServiceUnavailable() {
  const widget = useWidget();

  const handlerCloseModal = () => {
    widget?.setIsOpenModalServiceUnavailable(false);
    widget?.closeWidget();
  };

  return (
    <Box
      flexDir="column"
      alignItems="center"
      justifyContent={"center"}
      position={"fixed"}
      top="0"
      left="0"
      right="0"
      bottom="0"
      w="100%"
      h="100%"
      bg={"rgba(0, 0, 0, 0.5)"}
      zIndex="1000"
      display={"flex"}
      //onClick={handleOverlayClick}
    >
      <Box
        maxW="600px"
        mx="auto"
        //borderWidth="1px"
        //borderColor="gray.300"
        borderRadius="5px"
        overflow="hidden"
        boxShadow="sm"
        p={"6px"}
        bgColor={"white"}
        
      >
        {/* Purple header */}
        <Box
          bg="#553C9A"
          color="white"
          p={2}
          borderRadius="5px"
          textAlign="center"
          fontWeight="medium"
        >
          <Text fontSize="25px" fontWeight="bold">
              Pruébatelo en casa
          </Text>
          <Text fontSize="20px">
              antes de comprar
          </Text>
        </Box>

        {/* Content area */}
        <VStack p={4} bg="white" spacing={6} align="stretch" >
          <Flex alignItems="flex-start" gap={3} py={12}>
            {/* Location pin icon with circle */}
              <Box pr="10px">{svgLocation2}</Box>
            {/* Error message */}
            <Box>
              <Text fontWeight="medium" color="gray.800">
                Lo sentimos.
              </Text>
              <Text fontSize="sm" color="gray.600">
                Servicio no disponible en tu zona
              </Text>
            </Box>
          </Flex>

          {/* Accept button */}
          <Button
            w="full"
            bg="gray.900"
            color="white"
            py={6}
            borderRadius="md"
            fontWeight="medium"
            _hover={{ bg: "gray.800" }}
            onClick={handlerCloseModal}
          >
            Aceptar
          </Button>
        </VStack>
      </Box>
    </Box>
  );
}
