import {
  Flex,
  Img,
  Image,
  Text,
  Button,
  Link,
  Divider,
  Box,
  Skeleton,
  SkeletonText,
} from "@chakra-ui/react";
import { useProduct } from "../../providers/ProductProvider";

export const ProductDetail = () => {
  const prod = useProduct();

  if (prod?.product == null || prod?.loading) return;
  
  let displayProduct : boolean = (prod?.colors.length > 1) || ((prod?.productRelated ?? []).length > 0 /* && prod?.product.TNP !== prod?.visitedProduct?.TNP */) 

  return (
    <Flex pb={0} mt="15px" justifyContent="space-between" alignItems="center">
      <Button
        rounded={5}
        onClick={prod?.btnPrev}
        //textColor="gray.400"
        w="20px"
        isDisabled={prod?.loading}
        display={!prod?.productRelated || prod?.productRelated?.length == 0 ? "none" : "block"}
        style={{
          background: "#EDF2F7",
          color: "#553C9A", 
        }}
      >
        {"<"}
      </Button>
      <Flex direction="column" w="100%"  display={ displayProduct ? "block" : "none" }>
        <Skeleton isLoaded={!prod?.loading} ml={2}>
          <Link href={prod?.product.url}>
            <Image id="image" src={!prod?.loading ? prod?.image : ""} alt="" w="150px" m="auto" rounded="5px" opacity={1} fallbackSrc="https://trycloud.s3.us-east-2.amazonaws.com/assets/images/cloud-loading.gif"></Image>
          </Link>
        </Skeleton>
        <SkeletonText
          mt="4"
          noOfLines={2}
          spacing="2"
          skeletonHeight="4"
          display={!prod?.loading ? "none" : "block"}
        />
        <Text
          px={5}
          pt="10px"
          fontSize="15px"
          textAlign="center"
          display={prod?.loading ? "none" : "block"}
        >
          <span
            style={{
              color: "#bf2424fc",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          >{`$${prod?.product.price.toLocaleString('es-co')} `}</span>
          <span style={{ color: "#959191", fontSize: "15px" }}>
            {" "}
            <span>{ prod?.product.price > prod?.product.listPrice ? `$${prod?.product.listPrice.toLocaleString('es-co')}` : ''}</span>
          </span>
        </Text>
        <Text
          px={5}
          fontSize="16px"
          textAlign="center"
          display={prod?.loading ? "none" : "block"}
        >
          {prod?.product.name}
        </Text>
      </Flex>
      {prod?.colors.length > 1 &&
        !prod?.loading && ( //La evaluacion aqui es mayor que 1. para que aparezcan los colores.
          <>
            <Divider orientation="vertical" h="188px" mx="2" />
            <Flex
              direction="row"
              alignItems="center"
              wrap="wrap"
              //w="30%"
              rowGap="2px"
            >
              {prod?.colors?.map((color) => (
                <Box
                  key={color.colorName}
                  //border="1px solid #9f7aea"
                  //rounded="5px"
                >
                  <Img
                    rounded="100%"
                    opacity={1}
                    src={!prod?.loading ? color.image : ""}
                    alt=""
                    w="60px"
                    onClick={() => prod?.handlerColor(color)}
                    style={{ cursor: "pointer" }}
                  ></Img>
                  {/* <Text fontSize="10px" textAlign="center" color="purple.700">
                    {color.colorName}
                  </Text> */}
                </Box>
              ))}
            </Flex>
          </>
        )}
      <Button
        rounded={5}
        onClick={prod?.btnNext}
        //textColor="purple.700"
        isDisabled={prod?.loading}
        display={!prod?.productRelated || prod?.productRelated?.length == 0 ? "none" : "block"}
        style={{
          background: "#EDF2F7",
          color: "#553C9A", 
        }}
      >
        {">"}
      </Button>
    </Flex>
  );
};
