
//import { dataConfig } from "../../helper/Config";
import { Data } from "../../helper/Data";
import { DataConfigModel } from "../../models/DataConfigModel";
//import { SentryApi } from "../../helper/Sentry";
import { Brands } from "./Brands";

export class Bamers extends Brands{

    public modeTest: boolean = true;

    public startApp = (execute: (configResponse:DataConfigModel | null) => void, configResponse:DataConfigModel | null) => {
        if (this.allowRender()){
            this.runWhenElementExists(() => execute(configResponse));
        }
        this.mutationObserver(() => execute(configResponse));
    } 

    public getArea = (): HTMLElement | null | undefined => {
        const elementList = document.getElementsByTagName('ynk-custom-add-to-cart')
        if (!elementList || elementList.length == 0) {
            //SentryApi.captureException(new Error(`${dataConfig.metricsPrefix}: No se encontro el elemento necesario para renderizar el widget`));
            console.log("No se encontro el elemento necesario para renderizar el widget");
            return null;
        }
        const element = elementList[0] as HTMLElement;
        let elementTrycloud = document.getElementById('trycloud-area');
        if (!elementTrycloud){
            elementTrycloud = document.createElement('div');
            elementTrycloud.id = 'trycloud-area';
        }
        element?.appendChild(elementTrycloud);
        return document.getElementById("trycloud-area");
    }

    public hiddenElements = (): void => {

        let elements =[];
        elements.push(document.querySelector('ynk-custom-add-to-cart ynk-custom-product-variants'));
        elements.push(document.querySelector('ynk-custom-add-to-cart-v2'));
        elements.push(document.querySelector('button.btn.btn-primary.btn-block.glyphicon-map-marker.btn-icon.text-addToCartd.mb-4'));
        elements.push(document.querySelector('ynk-custom-add-to-cart form'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "display:none";
            }
        }
    }

    public showElement = (): void => {
        let elements =[];
        elements.push(document.querySelector('ynk-custom-add-to-cart ynk-custom-product-variants'));
        elements.push(document.querySelector('ynk-custom-add-to-cart-v2'));
        elements.push(document.querySelector('button.btn.btn-primary.btn-block.glyphicon-map-marker.btn-icon.text-addToCartd.mb-4'));
        elements.push(document.querySelector('ynk-custom-add-to-cart form'));

        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "";
            }
        }
    }

    /** Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        if (window.location.pathname.indexOf('/p/') > -1 || window.location.pathname.indexOf('/product/') > -1) {
            return true;
        }
        return false;
    }

    /** Takes the product  from link canonical and return it
     * @returns {string} the product id.
     */
    public getProductId = (): string => {
        if (Data.isDevelopmentEnv()) {
            const productData = document.querySelector("#productData");
            if (productData) {
                return productData.textContent || '';
            }
        }
        const canonicalLink = document.querySelector("link[rel='canonical']");
        if (!canonicalLink) return '';
        const href = canonicalLink.getAttribute("href");
        if (!href) return '';
        const paramData = href.split('/');
        if (paramData.length == 0) return '';
        let productId = '';
        for (let index = 0; index < paramData.length; index++) {
            if ( paramData[index].startsWith('BM')){
                productId = paramData[index];
            }
        }
        return productId;
    }

    /** Configura el observador de mutaciones.
     * @param execute 
     */
    public mutationObserver = (execute: () => void) => {
        const observer = this.mutationObserverInstance(execute);
        observer.observe(document.body, { childList: true, subtree: true });
       /*  const elementObservable = document.querySelector("body > app-root > ynk-custom-storefront > div > main > cx-page-layout > cx-page-slot.Summary.has-components");
        if (!elementObservable) return;
        observer.observe(elementObservable, { childList: true, subtree: true }); */
    }

    /** Instancia el observador de mutaciones.
     * @param execute 
     * @returns 
     */
    public mutationObserverInstance = (execute: () => void,) => {
        const observer = new MutationObserver((mutationsList) => {
            let nodeList = [];
            let nodeListTarget = [];
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    if (mutation.target instanceof HTMLElement) {
                        //if (mutation.target.nodeName == 'CX-STOCK-NOTIFICATION') {
                        //if (mutation.target.nodeName == 'YNK-CUSTOM-PRODUCT-REVIEWS') {
                        if (mutation.target.nodeName == 'CX-STOCK-NOTIFICATION') {
                            nodeList.push(mutation.target.nodeName);
                        }
                    }
                }
                nodeListTarget.push(mutation.target);
            }
            if (nodeList.includes('CX-STOCK-NOTIFICATION')) {
                if (!document.querySelector('trycloud-area')) {
                    console.log('ejecutando TryCloud...');
                    execute();
                } else {
                    console.log('TryCloud already running...');
                }
                console.log('mutationObserver-Target: ', nodeListTarget);
            }
        });
        return observer;
    }


    public runWhenElementExists = (execute: () => void) => {
        const element = document.querySelector('CX-STOCK-NOTIFICATION');
        if (element) {
            console.log('El elemento [ADD] existe en el DOM.');
            execute();
            console.log('ejecutando TryCloud...');
        } else {
            console.log('El elemento [ADD] no existe en el DOM.');
            // Esperar y volver a verificar después de un tiempo
            setTimeout(this.runWhenElementExists.bind(null, execute), 1000);
        }
    }
}