import { Box, Flex, Img } from "@chakra-ui/react";
import { useWidget } from "../../providers/WidgetProvider";

export const HeaderOff = () => {
  const widget = useWidget();
  if (widget == null) return(<></>);
  return (
    <Flex direction="column"  w="100%">
      <Flex direction="row" alignItems={"baseline"} >
        <Box id="header-trycloud" pt={5} fontSize="18px" fontWeight="400" color={"#404040"}>
          Pruébatelo gratis con 
        </Box>
        <Img src="https://trycloud.s3.us-east-2.amazonaws.com/assets/images/Type-logo-Trycloud.png" 
            alt="trycloud logo"
            h={"14px"}
            w={"62px"}
            ml={2}
            opacity={1}
            />
      </Flex>
      <Box
        id="header-trycloud"
        fontSize="14px"
        lineHeight="16px"
        textDecoration={"underline"}
        textAlign={"left"}
        color={"#404040"}
        cursor={"pointer"}
        onClick={() => widget.openCloseModalHowTowork(true)}
      >
        Conoce más.
      </Box>
    </Flex>
  );
};