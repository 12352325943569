import { hotjar } from "react-hotjar";
import { Data } from "./Data";

export class Hotjar {

    /** Initialize the hotjar. */
    static initialize() {
        if (!hotjar.initialized()){
            const hotjarId: number = Hotjar.getHotjarId();
            hotjar.initialize(hotjarId, 6);
        }
    }

    /** Send an event */
    static event(eventName: string) {
        if (!hotjar.initialized()){
            Hotjar.initialize();
        }
        hotjar.event(eventName);
    }

    /**
     * Retrieves the Hotjar ID based on the host ID.
     * @return {number} The Hotjar ID corresponding to the host ID.
     */
    public static getHotjarId = (): number => {
        const hostId = Data.getHostId();
        switch (hostId) {
            case '546556'://Newbalance
            return 3670315;
            case '781264'://Mali
            return 3846739;
            default:
            return 0;
        }
    }

    public static activate() {
        Hotjar.event('trycloud_activated');
    }

    public static deactivate() {
        Hotjar.event('trycloud_deactivated');
    }
    
    /** Triggers the 'modal_timer' event in Hotjar.
     * This function is a static method that calls the `event` method of the `Hotjar` class with the argument 'modal_timer'.
     * It is used to track the timing of a modal being displayed.
     * @return {void} This function does not return anything.
     */
    public static funnelModalTimer() {
        Hotjar.event('modal_timer');
    }

    public static funnelModalAdress() {
        Hotjar.event('modal_adress');
    }

    public static funnelAnonimo() {
        Hotjar.event('anonimo');
    }

    public static funnelSizeNotAvailable() {
        Hotjar.event('size_not_available');
    }

    public static funnelAddToCart() {
        Hotjar.event('add_to_cart');
    }
    
    public static funnelOTP() {
        Hotjar.event('otp');
    }

    public static funnelCheckout() {
        Hotjar.event('checkout');
    }

    public static funnelConfirmation() {
        Hotjar.event('confirmation');
    }

    public static funnelSuccess() {
        Hotjar.event('success');
    }   
}