
/** Checks the URL parameters and updates the local storage accordingly.
 * @return {void} This function does not return anything.
 */
export const checkParameters = () => {
    var url = document.location.href;
    // If the url does not contain the parameter, return.
    if (url.indexOf ('?') < 1) {
        return;
    }
    // Get the parameter, from the url.
    var params = url.split ('?') [1];
    var urlParams = new URLSearchParams(params);
    const trycloud = urlParams.get("Trycloud");
    const tryCloudDev = urlParams.get("Tryclouddev");
    // Update the localStorage when modeTest is activated in url param.
    if (tryCloudDev && tryCloudDev ===  "on"){
        localStorage.setItem("tryCloudDev", "true");
    }else if(tryCloudDev && tryCloudDev ===  "off"){
        localStorage.setItem("tryCloudDev", "false");
    }
    // Update the localStorage when trycloud is activated in url param.
    // this allow to render the widget opend.
    if (trycloud && trycloud ===  "on"){
        localStorage.setItem("tryCloud", "true");
    } else if(trycloud && trycloud ===  "off"){
        localStorage.setItem("tryCloud", "false");
    }
}

/** Checks if the modeTest is activated in the local storage.
 * @return {boolean} Whether the modeTest is activated
 */
export const isModeTestOn = () => {
    return localStorage.getItem("tryCloudDev") === "true";
}