import { Box, Flex, Button } from "@chakra-ui/react";
import { ProductOutStock } from "./ProductOutStock";
import { ProductDetail } from "./ProductDetail";
import { ProductSize } from "./ProductSize";
import { ShoppingCart } from "./shopingCart/ShoppingCart";
import { AuthService } from "../../services/AuthService";
import { useShoppingCart } from "../../providers/ShoppingCartProvider";
import { useWidget } from "../../providers/WidgetProvider";
import { useAddress } from "../../providers/AddressProvider";
import { Address } from "./Address";

export const Product = () => {
  
  const widget = useWidget();

  if (widget == null) return(<></>);
  
  const shop = useShoppingCart();
  const addr = useAddress();
  
  // If the widget is not on, it will not be rendered.
  if (!widget.isOn) return; 

  const changeAddress = () => {
    widget.openCloseModalAddress(true);
  }

  const nextStep = async() =>{
    if(AuthService.authModel.isAnonimo){
      widget.handlerSteps('phone');
      return;
    }
    if(!addr?.address || addr?.address?.address == addr?.address?.city_name || addr?.address?.additional_info == "anonymous"){
      widget.handlerSteps('address');
      return;
    } 
    widget.handlerSteps("data-customer");
  } 

  return (
    <Flex direction="column" px={5}>
      <ProductOutStock />
      <ProductDetail />
      <ProductSize />
      <ShoppingCart />
      <Address additional_info={addr?.address?.additional_info} address={addr?.address?.address} changeAddress={changeAddress} />
      <Button
        w="90%"
        mx="auto"
        mt={2}
        rounded="5px"
        //bg="purple.700"
        //color="white"
        style={{ 
          background: "#553C9A",
          color: "white",
        }}
        isDisabled={shop?.cart.length == 0}
        _hover={{ bg: "purple.400" }}
       /*  isLoading={sedingOrder} */
        loadingText="Procesando"
        onClick={nextStep}
      >
        Finalizar
      </Button>
      <Box mt={1} fontSize="12px" color={"#808080"} textAlign={"center"}>No requiere tarjeta de crédito</Box>
    </Flex>
  );
};
