import { Brands } from "./Brands";
import { DataConfigModel } from "../../models/DataConfigModel";
import { GlobalPixel } from "../../helper/pixel/GlobalPixel";

export class BosiCo extends Brands {

    public modeTest: boolean = true;

    public startApp = (execute: (configResponse:DataConfigModel | null) => void, configResponse:DataConfigModel | null) => {
        localStorage.setItem("tcdIsRender", "false");
        this.mutationObserver(() => execute(configResponse));
    } 
  
    public getArea = () : HTMLElement | null | undefined => {
        let elementParent = document.querySelector("div.vtex-flex-layout-0-x-flexCol.vtex-flex-layout-0-x-flexCol--product-page-container-details-summary.ml0.mr0.pl0.pr0.flex.flex-column.h-100.w-100");
        // INFO: Seccion del seleccion de talla.:
        // NOTE: Cuando uso esta seccion la pagina duplica el borton de agregar al carrito.
        //       Parece que tiene un observer que identifica que se mueve el elemento y lo vuelve a crear.
        //let elementChild = document.querySelector("div.vtex-rich-text-0-x-wrapper.vtex-rich-text-0-x-wrapper--select-variation");
        // INFO: seccion del Boton de Agregar al Carrito.
        let elementChild = document.querySelector("div.flex.mt0.mb0.pt0.pb0.justify-between.vtex-flex-layout-0-x-flexRowContent.vtex-flex-layout-0-x-flexRowContent--product-page-quantity-addToCart.items-stretch.w-100")
        // INFO: Seccion de Addi.
        //let elementChild = document.querySelector("addi-widget");
        if (elementChild instanceof HTMLElement && elementChild != null && elementParent instanceof HTMLElement && elementParent != null) {
            elementChild = elementChild?.parentElement?.parentElement as HTMLElement | null;
            const trycloudArea = document.createElement("div");
            trycloudArea.style.cssText = "margin-top: 10px;";
            trycloudArea.setAttribute("id", "trycloud-area");
            trycloudArea.setAttribute("name", "trycloud-area");
            elementParent.insertBefore(trycloudArea, elementChild?.nextSibling as HTMLElement);
            return trycloudArea;
        }
        console.log("No se encontro el elemento necesario para renderizar el widget");
        return null;
    }

    public hiddenHeader = (hidden: boolean) => {
        let elements =[];
        elements.push(document.querySelector("body > div.render-container.render-route-store-product > div.render-provider > div.vtex-store__template.bg-base > div > div > div > div.vtex-sticky-layout-0-x-wrapper.vtex-sticky-layout-0-x-wrapper--header-desktop-sticky"));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = hidden ? "display:none" : "";
            }
        }
    }

    selectElements = () => {
        let elements =[];
        elements.push(document.querySelector("addi-widget"));
        elements.push(document.querySelector("div.vtex-rich-text-0-x-wrapper.vtex-rich-text-0-x-wrapper--select-variation"));
        elements.push(document.querySelector("div.flex.mt0.mb0.pt0.pb0.justify-between.vtex-flex-layout-0-x-flexRowContent.vtex-flex-layout-0-x-flexRowContent--product-page-sku-selector-size.items-stretch.w-100"));
        elements.push(document.querySelector("div.flex.mt0.mb0.pt0.pb0.justify-between.vtex-flex-layout-0-x-flexRowContent.vtex-flex-layout-0-x-flexRowContent--product-page-quantity-addToCart.items-stretch.w-100"));
        // MOBILE
        elements.push(document.querySelector("div.flex-none.flex-ns.mt0.mb0.pt0.pb0.justify-start.vtex-flex-layout-0-x-flexRowContent.vtex-flex-layout-0-x-flexRowContent--product-page-container-button-add-to-cart-mobile.items-stretch.w-100"));
        elements.push(document.querySelector("div.bosico-store-components-3-x-skuSelectorContainer.bosico-store-components-3-x-skuSelectorContainer"));
        return elements;
    }

    public hiddenElements = () => {
        let elements = this.selectElements(); 
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "display:none";
            }
        }
    }

    public showElement = () => {
        let elements = this.selectElements(); 
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "";
            }
        }
    }

    /** Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        if (window.location.pathname.indexOf('/p') > -1){
            return true;
        }
        return false;
    }

    /** Takes the product model from url and return it
     * @returns {string} the product model.
     */
    public getProductModel = (): string => {
        const metaElement = document.querySelector('meta[property="product:retailer_item_id"]');
        if (!metaElement) return '';
        return metaElement.getAttribute('content') || '';
    };
    

    public specialRunWhenElementExists = (execute: () => void, numRetries = 10) : Promise<boolean> => {
        localStorage.setItem("tcdIsRender", "true");
        return new Promise((resolve) => {
            const elementButtonDesktop = document.querySelector("button.vtex-button.bw1.ba.fw5.v-mid.relative.pa0.lh-solid.br2.min-h-regular.t-action.bg-action-primary.b--action-primary.c-on-action-primary.hover-bg-action-primary.hover-b--action-primary.hover-c-on-action-primary.pointer.w-100");
            //const elementProductData = this.getProductIdArea();
            if (elementButtonDesktop || numRetries <= 0) {
                // Hacer algo con el elemento
                console.log('TryCloud: Elemento encontrado.');
                if (!document.querySelector('#trycloud-area')) {
                    execute();
                    resolve(true);
                    localStorage.setItem("tcdIsRender", "false");
                    console.log('TryCloud: El widget se ejecutó correctamente.');
                } else {
                    console.log('TryCloud: El widget ya se encuentra renderizado. No se ejecutó de nuevo. Intentos:', numRetries);
                }
            } else {
                console.log('TryCloud: Esperando el elemento DOM. Intento:', numRetries);
                // Esperar y volver a verificar después de un tiempo
                setTimeout(this.specialRunWhenElementExists.bind(null, execute, numRetries - 1), 1000);
            }
        });
    }

    /** Configura el observador de mutaciones.
     * @param execute 
     */
    public mutationObserver = (execute: () => void) => {
        const observer = this.mutationObserverInstance(execute);
        observer?.observe(document.body, { childList: true, subtree: true });
    }

    /** Instacia el observador de mutaciones.
     * @param execute 
     * @returns 
     */
    public mutationObserverInstance = (execute: () => void,) => {
        var nodeList = [];
        const observer = new MutationObserver((mutationsList) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    if (mutation.target instanceof HTMLElement) {
                        if (this.allowRender()) {
                            nodeList.push(mutation.target.nodeName);
                        } else {
                            document.getElementById('trycloud-area')?.remove();
                        }
                    }
                }
            }
            if (localStorage.getItem("tcdIsRender") === "false" && nodeList.length > 0) {   
                 // If don't is render now and has changes in the DOM and is allow render (page product) and widget is not render yet.
                if (nodeList.length > 0 && this.allowRender() && !document.querySelector('#trycloud-area')) {
                    console.log(`TryCloud: NodeList: ${nodeList.length} | isExecuted: ${localStorage.getItem("tcdIsRender")} | allowRender: ${this.allowRender()}`);
                }        
                // compare productId
                this.compareProductId(); 
                nodeList = [];
                if (!document.querySelector('#trycloud-area')) {
                    console.log('TryCloud: Iniciando desde el observador...');
                    this.specialRunWhenElementExists(execute);
                } else {
                    // Validate if widget is Open to hide elements.
                    if (localStorage.getItem("tryCloud") === "true") {
                        this.hiddenElements();
                    }
                    console.log('TryCloud: Cambios detectados, pero el widget ya se encuentra renderizado.');
                }
            }
        });
        return observer;
    }

    /** Send meta view content to Facebook Pixel */
    public sendMetaViewContent = ()  =>{ 
        const productId = this.getProductModel();
        const name = document.querySelector("head > title") as HTMLElement;
        const priceMeta = document.querySelector("meta[property='product:price:amount']");

        const price = priceMeta ? priceMeta.getAttribute("content") : "";
        if (productId && name) {
            const metaViewContent = {
                "content_ids": [`BOSCO${productId}`],
                "content_type": "product",
                "content_name": name.innerText,
                "value": price,
                "currency": "COP"
            };
            const tiktokViewContent = {
                "content_id": `BOSCO${productId}`, // Unique product/content ID
                "content_type": 'product', // 'product' or 'article' or another category
                "currency": 'COP', 
                "value": price // Price of the viewed product or value of the content
            };
            GlobalPixel.sendMetaViewContent(metaViewContent, tiktokViewContent);
        }
    }

    /** Compare productId to know if it has changed */
    public compareProductId = () => {
        const productId = this.getProductModel();
        if (productId) {
            if (productId !== localStorage.getItem("tcdProductId")) {
                localStorage.setItem("tcdProductId", productId);``
                document.getElementById('trycloud-area')?.remove();
            }
        }
    }
}