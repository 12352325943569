import { Box, Flex, Img, Link, Skeleton } from "@chakra-ui/react";
import { useShoppingCart } from "../../../providers/ShoppingCartProvider";
import { useProduct } from "../../../providers/ProductProvider";

export const ShoppingCart = () => {

  const shop = useShoppingCart();
  const prod = useProduct();

  const delVariation = async(tn: string) => {
    try {
      prod?.setLoading(true);
      await shop?.delShoppingCart(tn);
      const newCart = await shop?.getShoppingCart();
      await prod?.fetchProduct(newCart);
    } finally {
      prod?.setLoading(false);
    }
  }

  // Show cart if cart is not empty.
  if (shop?.cart.length == 0) return;

  return (
    <Skeleton w={"100%"} isLoaded={!prod?.loading}>
      <Flex direction="row" columnGap="5px" m={5} bg="#F4F4F4" shadow="md" rounded="5px">
        <Flex direction="column" w="60%">
          <Box color="purple.700" fontSize="14px" fontWeight="bold" pl="5px" textAlign={"left"}>
            Mi selección
          </Box>
          <Flex p="5px">
            {shop?.cart?.map((item) => {
              return (
                <Flex direction="column" mr="-5px" key={item.cardId} >
                  {
                    item.url != null && item.url != "" && item.url != undefined ? 
                    <Link href={item.url}>
                      <Img src={item.image} boxSize="60px" opacity={1} rounded="50px" shadow="md" border="solid 1px white"></Img>
                    </Link>
                    :
                    <Img src={item.image} boxSize="60px" opacity={1} rounded="50px" shadow="md" border="solid 1px white"></Img>
                  }
                  <Box textAlign="center" color="purple.700" fontSize="14px">
                    {item.sizeName?.slice(0, 4)}
                  </Box>
                  <Link color="#A20000" fontSize="12px" fontWeight="bold" textDecoration={"none"} textAlign="center" onClick={() => delVariation(item.upc)}>Quitar</Link>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        <Flex direction="column" justifyContent="center" w="40%">
          <Box color="purple.700" fontWeight="bold" fontSize="21px" textAlign={"center"}>
            {`${shop?.cart.length} de 3`}
          </Box>
        </Flex>
      </Flex>
    </Skeleton>
  );
};
