import { Data } from "../../helper/Data";
import { GlobalPixel } from "../../helper/pixel/GlobalPixel";
import { DataConfigModel } from "../../models/DataConfigModel";
import { ProductService } from "../../services/ProductService";
import { Brands } from "./Brands";

export class PilatosCo extends Brands{

    public modeTest: boolean = false;
    
    public startApp = (execute: (configResponse:DataConfigModel | null) => void, configResponse:DataConfigModel | null) => {
        localStorage.setItem("tcdIsRender", "false");
        this.mutationObserver(() => execute(configResponse));
    } 
  
    public getArea = () : HTMLElement | null | undefined => {
        
        let element = document.querySelector('div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--contentButtonQuantity');
        if (!element) {
            // Botón de agregar al carrito mobile...
           element = document.querySelector('div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--productButton');
        }
        if (element != null) {
            const trycloudArea = document.createElement("div");
            trycloudArea.setAttribute("id", "trycloud-area");
            trycloudArea.setAttribute("name", "trycloud-area");
            element?.parentNode?.parentNode?.insertBefore(trycloudArea, element?.parentNode.nextSibling);
            return trycloudArea;
        }
        console.log("Trycloud: No se encontro el elemento necesario para renderizar el widget");
        return null;
    }

    public hasOwnerShipElement = () : boolean  => {
        const element = document.querySelector("#ownship");
        return element !== null;
    }

    public hiddenElements = () => {
        let elements =[]; 
        //box color
        elements.push(document.querySelector('div.flex.flex-column.vtex-store-components-3-x-skuSelectorSubcontainer--color.mb7.vtex-store-components-3-x-skuSelectorSubcontainer'));
        //box talla
        elements.push(document.querySelector('div.flex.flex-column.vtex-store-components-3-x-skuSelectorSubcontainer--talla.mb7.vtex-store-components-3-x-skuSelectorSubcontainer'));
        // box add to cart
        elements.push(document.querySelector('div.vtex-flex-layout-0-x-flexColChild.vtex-flex-layout-0-x-flexColChild--product-description.vtex-flex-layout-0-x-flexColChild--stack-product.pb0'));
        // Box add to cart Mobile.
        elements.push(document.querySelector('div.flex-none.flex-ns.mt0.mb0.pt0.pb0.justify-start.vtex-flex-layout-0-x-flexRowContent.vtex-flex-layout-0-x-flexRowContent--productButton.items-stretch.w-100'));
        // Box of quantity
        elements.push(document.querySelector('div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--contentButtonQuantity'));
        // Elementos de productos similares
        elements.push(document.querySelector('div.seg-shop-similar-button'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "display:none";
            }
        }
    }

    public hiddenHeader = (hidden: boolean) => {
        let elements =[];
        elements.push(document.querySelector('header'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = hidden ? "display:none" : "";
            }
        }
    }

    public showElement = () => {
        let elements =[]; 
        //box color
        elements.push(document.querySelector('div.flex.flex-column.vtex-store-components-3-x-skuSelectorSubcontainer--color.mb7.vtex-store-components-3-x-skuSelectorSubcontainer'));
        //box talla
        elements.push(document.querySelector('div.flex.flex-column.vtex-store-components-3-x-skuSelectorSubcontainer--talla.mb7.vtex-store-components-3-x-skuSelectorSubcontainer'));
        // box add to cart
        elements.push(document.querySelector('div.vtex-flex-layout-0-x-flexColChild.vtex-flex-layout-0-x-flexColChild--product-description.vtex-flex-layout-0-x-flexColChild--stack-product.pb0'));
        // Box add to cart Mobile.
        elements.push(document.querySelector('div.flex-none.flex-ns.mt0.mb0.pt0.pb0.justify-start.vtex-flex-layout-0-x-flexRowContent.vtex-flex-layout-0-x-flexRowContent--productButton.items-stretch.w-100'));
        // Box of quantity.
        elements.push(document.querySelector('div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--contentButtonQuantity'));
        // elements similars products.
        elements.push(document.querySelector('div.seg-shop-similar-button'));
       
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "";
            }
        }
    }

    /**
     * Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        if (!this.hasOwnerShipElement()){ 
            return false;
        }
        if (window.location.pathname.indexOf('/p') > -1){
            return true;
        }
        return false;
    }

    public getProductModel = (): string => {
        const metaElement = document.querySelector('meta[property="product:retailer_item_id"]');
        if (!metaElement) return '';
        const productModel = metaElement.getAttribute('content');
        if (productModel === null) return '';
        // Aqui la llamada para actualizar productos.

        return productModel;
    };

    /** Send meta view content to Facebook Pixel */
    public sendMetaViewContent = ()  =>{ 
        const productId = this.getProductModel();
        const name = document.querySelector("head > title") as HTMLElement;
        const price = Data.getMetaContentByProperty('product:price:amount');
        if (productId && name) {
            const metaViewContent = {
                "content_ids": [`PILCO${productId}`],
                "content_type": "product",
                "content_name": name.innerText,
                "value": price,
                "currency": "COP"
            };
            const tiktokViewContent = {
                "content_id": `PILCO${productId}`, // Unique product/content ID
                "content_type": 'product', // 'product' or 'article' or another category
                "currency": 'USD', 
                "value": price // Price of the viewed product or value of the content
            };
            GlobalPixel.sendMetaViewContent(metaViewContent, tiktokViewContent);

        }
    }
    
    public getSkuId =(): string | null => {
        return Data.getMetaContentByProperty('product:sku')
    }

    public specialRunWhenElementExists = (execute: () => void, numRetries = 10) : Promise<boolean> => {
        localStorage.setItem("tcdIsRender", "true");
        return new Promise((resolve) => {
            /* const elementSimilar = document.querySelector('div.seg-shop-similar-button'); */
            const elementButtonDesktop = document.querySelector('div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--contentButtonQuantity')
            const elementButtonMobile = document.querySelector('div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--productButton');
            //const elementProductData = this.getProductIdArea();
            //if ((elementSimilar && (elementButtonDesktop || elementButtonMobile)) || numRetries <= 0) {
            if (elementButtonDesktop || elementButtonMobile || numRetries <= 0) {
                console.log('TryCloud: Elemento encontrado.');
                ProductService.updateProductBySkuId();
                if (!document.querySelector('#trycloud-area')) {
                    execute();
                    resolve(true);
                    localStorage.setItem("tcdIsRender", "false");
                    console.log('TryCloud: El widget se ejecutó correctamente.');
                } else {
                    console.log('TryCloud: El widget ya se encuentra renderizado. No se ejecutó de nuevo. Intentos:', numRetries);
                }
            } else {
                console.log('TryCloud: Esperando el elemento DOM. Intento:', numRetries);
                // Esperar y volver a verificar después de un tiempo
                setTimeout(this.specialRunWhenElementExists.bind(null, execute, numRetries - 1), 1000);
            }
        });
    }

    /** Configura el observador de mutaciones.
     * @param execute 
     */
    public mutationObserver = (execute: () => void) => {
        const observer = this.mutationObserverInstance(execute);
        observer?.observe(document.body, { childList: true, subtree: true });
    }

    /** Instacia el observador de mutaciones.
     * @param execute 
     * @returns 
     */
    public mutationObserverInstance = (execute: () => void,) => {
        var nodeList = [];

        const observer = new MutationObserver((mutationsList) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    if (mutation.target instanceof HTMLElement) {
                        if (this.allowRender()) {
                            nodeList.push(mutation.target.nodeName);
                        }
                    }
                }
            }
            // If don't is render now and has changes in the DOM and is allow render (page product) and widget is not render yet.
            if (localStorage.getItem("tcdIsRender") === "false" && nodeList.length > 0 && this.allowRender() && !document.querySelector('#trycloud-area')) {
                console.log(`TryCloud: NodeList: ${nodeList.length} | isExecuted: ${localStorage.getItem("tcdIsRender")} | allowRender: ${this.allowRender()}`);
            }
            if (localStorage.getItem("tcdIsRender") === "false" && nodeList.length > 0) {            
                nodeList = [];
                if (!document.querySelector('#trycloud-area')) {
                    console.log('TryCloud: Iniciando desde el observador...');
                    this.specialRunWhenElementExists(execute);
                } else {
                    console.log('TryCloud: Cambios detectados, pero el widget ya se encuentra renderizado.');
                }
            }
        });
        return observer;
    }
}